import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableauCredentials } from "../services/api/api.types";

interface AuthSlice {
    credentials: TableauCredentials | null;
    jwt: string | null;
    refreshTimeout?: ReturnType<typeof setTimeout>;
}

interface AuthSuccessPayload {
    credentials: NonNullable<AuthSlice["credentials"]>;
    jwt: NonNullable<AuthSlice["jwt"]>;
    refreshTimeout: NonNullable<AuthSlice["refreshTimeout"]>;
}

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        credentials: null,
        jwt: null,
        refreshTimeout: undefined,
    } as AuthSlice,
    reducers: {
        authenticate: (state, action: PayloadAction<AuthSuccessPayload>) => {
            const { credentials, jwt, refreshTimeout } = action.payload;
            clearTimeout(state.refreshTimeout);

            state.refreshTimeout = refreshTimeout;
            state.jwt = jwt;
            state.credentials = credentials;
        },
        logout: (state) => {
            clearTimeout(state.refreshTimeout);
            state.refreshTimeout = undefined;
            state.credentials = state.jwt = null;
        },
    },
});
export const { authenticate, logout } = authSlice.actions;
export default authSlice.reducer;
