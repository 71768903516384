/**
 * Simple D.R.Y. method to join an array of strings into a single string, each item separated by a space.  Will
 * automatically filter out any falsy (i.e. `undefined`, `null`, or empty strings) prior to joining.  Intended to ease
 * applying multiple classes - possibly conditionally - to elements.
 *
 * @example
 * const someVar = "XYZ";
 * stringJoin("abc", "def", test ? 'included' : null, someVar);
 * // if `test` is truthy, returns
 * "abc def included XYZ"
 * // else returns
 * "abc def XZY"
 *
 * @returns {String} Returns a single string comprised of the input items joined by an empty space, with falsy items excluded.
 */
export function stringJoin(...strings: (string | undefined | null)[]): string {
    return strings.filter((s) => !!s).join(" ");
}

// Associated Press style says numbers 1-9 should be words, everything else is the numeral.  We'll expand a bit to include 0 and 11-15.
const numRef = Object.freeze(["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen"]);
const numberFormat = Intl.NumberFormat();
export function pluralNumberString(num: number, singular: string, plural: string): string {
    let textNum = numRef[num] ?? numberFormat.format(num);
    return `${textNum} ` + (num === 1 ? singular : plural);
}

export const prodconsole: Console = process.env.CONTEXT === "production" ? (((..._: string[]) => {}) as unknown as Console) : console;
