import React from "react";

import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "./src/styles/global.scss";

import { GatsbyBrowser } from "gatsby";
import { RootProvider } from "./src/contexts/root.context";
import { Provider } from "react-redux";
import { store } from "./src/store/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { MSALInitialConfig } from "./src/services/api/api.constants";
import { MsalProvider } from "@azure/msal-react";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
    const msalInstance = new PublicClientApplication(MSALInitialConfig);
    return (
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <RootProvider>{element}</RootProvider>
            </Provider>
        </MsalProvider>
    );
};

// Fix issues with pages not scrolling to the top after loading.
export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({ routerProps: { location }, getSavedScrollPosition }) => {
    const currentPosition = getSavedScrollPosition(location);

    setTimeout(() => {
        if (typeof window !== "undefined") {
            if (location.hash) window.location.hash = location.hash;
            else window.scrollTo(...(currentPosition || [0, 0]));
        }
    }, 0);

    return false;
};
